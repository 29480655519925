/* eslint-disable no-template-curly-in-string */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import comingsoon from "@/assets/comingSoon.png";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  Grid,
  Link,
  Pagination,
  Paper,
  Slider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { TopHeadingLayout } from "@/components/layout/TopHeadingLayout";
import HomeScreenImg from "@/assets/earth.jpg";
import quickDrawsImg from "@/assets/quickDrawsImg.png";
import { useAuth } from "@/lib/auth";
import { useLotteries } from "../api/getLottery";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import Countdown from "react-countdown";
import { theme } from "@/components/Elements/theme";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  paperClass: {
    padding: theme.spacing(2),
    textAlign: "center",
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
  },
}));

const dataValue = {
  heading: "Live Lottery Spin",
  subHeading:
    "Buy lottery tickets online for the top international lotteries. Safely and securely playfor the biggest jackpots in the entire world from the comfort of home.",
};

export default function LiveLotteries() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  console.log(currentPage, "currentPage");
  const [totalPages, setTotalPages] = useState<number>(1);
  const limit = 10;
  const classes = useStyles();
  const { user } = useAuth();
  const LotteryQuery: any = useLotteries({ page: currentPage, limit });
  console.log(LotteryQuery, "LotteryQuery");
  useEffect(() => {
    if (LotteryQuery?.data && LotteryQuery?.data?.totalPages) {
      setTotalPages(LotteryQuery?.data.totalPages);
    }
  }, [LotteryQuery?.data]);

  if (LotteryQuery.isLoading || LotteryQuery.isFetching) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (LotteryQuery?.data?.data.length === 0) {
    return (
      <Box
        className="w-full h-48 flex justify-center items-center"
        sx={{
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          border: "1px solid",
          marginTop: "90px",
          minHeight: "calc(100vh - 330px)",
          display: "flex",
          alignItems: "center",
          "@media screen and (max-width:992px)": {
            marginTop: "66px",
          },
        }}
      >
        <img src={comingsoon} alt="comingsoon" style={{ width: "100%" }} />
      </Box>
    );
  }

  var content = {
    color: "#ffff",
    backgroundColor: "#050518",
    borderRadius: "12px",
  };
  var center = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const gridItem = {};
  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setCurrentPage(newPage); // Update current page
  };
  return (
    <Box
      sx={{
        background: `linear-gradient(89.41deg, #1B1C2D 7.81%, rgba(27, 28, 45, 0) 38.09%), linear-gradient(1.53deg, #1B1C2D 13.32%, rgba(27, 28, 45, 0) 50.41%), linear-gradient(46.49deg, #1B1C2D 14.7%, rgba(27, 28, 45, 0) 43.39%), url(${HomeScreenImg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: "calc(100vh - 320px)",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        p: { xs: "14px", md: "50px" },
        paddingTop: { md: "170px", xs: "120px" },
      }}
    >
      <Box
        sx={{
          maxWidth: "95%",
          margin: "0 auto",
          [theme.breakpoints.down("md")]: {},
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "768px",
            margin: {
              xs: "10px auto 30px",
              sm: "30px auto 40px",
              md: "60px auto",
            },
            textAlign: "center",
            fontFamily: '"Ubuntu", sans-serif',
            fontSize: "25px",
            lineHeight: "1.5",
          }}
        >
          <Typography
            component="h4"
            sx={{
              textTransform: "capitalize",
              fontWeight: "500 !important",
              marginBottom: { xs: "18px", md: "25px" },
              fontSize: { xs: "20px", md: "30px" },
            }}
          >
            live Lottery
          </Typography>
          {/* <Typography
            component={"p"}
            sx={{
              color: "rgba(255, 255, 255, 0.7)",
              fontSize: "16px",
              lineHeight: "1.5",
            }}
          >
            Buy lottery tickets online for the top international lotteries.
            Safely and securely playfor the biggest jackpots in the entire world
            from the comfort of home.
          </Typography> */}
        </Box>
        <Box
          sx={{
            padding: { md: "40px 40px 60px", xs: "20px 20px 40px" },
            backgroundColor: "rgb(57 57 57 / 80%)",
            borderRadius: "10px",
          }}
        >
          <Grid container display={"flex"} direction={"column"}>
            <Grid item xs={12}>
              <Box sx={{ pt: { lg: "40px", sm: "30px", xs: "20px" } }}>
                <Grid container spacing={3} justifyContent={"center"}>
                  {LotteryQuery?.data?.data?.map((value, index) => (
                    //console.log(value)
                    <Grid item key={index} xs={12} sm={6} md={6} lg={4} xl={4}>
                      <Card
                        sx={{
                          display: "flex",
                          flexDirection: {
                            xs: "column",
                            sm: "column",
                            md: "row",
                          },
                          maxWidth: "780px",
                          cursor: "pointer",
                          position: "relative",
                          borderRadius: "16px !important",
                          overflow: "hidden",
                          "&:hover": {
                            backgroundColor: "#fff !important",
                            color: "black !important",
                            boxShadow: 20,

                            "& div div .MuiDivider-root": {
                              borderBottomColor: "black !important", // Change the color of the Divider component
                            },
                            "& div div div p": {
                              backgroundColor: "#fff !important",
                              color: "black !important",
                            },
                            "& div div .MuiCardContent-root": {
                              backgroundColor: "#fff !important",
                              color: "black !important",
                            },
                            "& div div div div.MuiPaper-root": {
                              border: "1.23px solid #00000038 !important",
                              background: "#fff",
                              boxShadow: "none",
                            },
                            "& div div div div.MuiPaper-root .MuiCardContent-root":
                              {
                                border: "1.23px solid #00000038 !important",
                              },
                          },
                        }}
                        style={content}
                        onClick={() => {
                          window.location.assign(
                            "/app/liveLotteries/" + value._id
                          );
                        }}
                      >
                        <Chip
                          label={`${value?.numOfTicketSold || 0}  Sold`}
                          sx={{
                            position: "absolute",
                            transform: "rotate(45deg)",
                            top: "2px",
                            right: "-31px",
                            backgroundColor: "#fff",
                            color: "Red",
                            borderRadius: "4px",
                            padding: "4px 17px",
                            fontSize: "12px",
                            fontWeight: "bold",
                            zIndex: 1,
                          }}
                        />
                        <CardMedia
                          component="img"
                          image={
                            value.image
                              ? `${process.env.API_URL_PREFIX}/api/v1/admin/uploads/${value.image}`
                              : quickDrawsImg
                          }
                          alt="Live from space album cover"
                          sx={{
                            height: {
                              xs: "200px !important",
                              md: "250px !important",
                            },
                            width: { xs: "100%", md: "45%" },
                            zIndex: "2",
                            backgroundPosition: "initial",
                            objectFit: "unset !important",
                            borderRadius: "10px",
                          }}
                        />

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            cursor: "pointer",
                            width: { xs: "100%", md: "65%" },
                          }}
                        >
                          <CardContent style={center} sx={{}}>
                            <Stack
                              flex="1 0 1"
                              justifyContent={center}
                              alignItems={center}
                              sx={{
                                "&:hover": {
                                  backgroundColor: "#fff !important",
                                  color: "black !important",
                                },
                              }}
                            >
                              <Box>
                                <Button
                                  sx={{
                                    px: "19px",
                                    py: "7.73px",
                                    marginBottom: "19px !important",
                                    backgroundColor: "rgba(60, 55, 255, 0.15)",
                                    color: "#3C37FF",
                                    borderRadius: "19px",
                                    display: { xs: "none", md: "block" },
                                  }}
                                >
                                  {value.lotteryName}
                                </Button>
                                <Divider
                                  id="cardLine"
                                  sx={{
                                    display: { xs: "none", md: "block" },
                                    borderBottom:
                                      "0.727162px dashed rgba(255, 255, 255, 0.5)",
                                    mx: "-80px",
                                    "&:hover": {
                                      borderBottom:
                                        "0.727162px dashed black !important",
                                    },
                                  }}
                                />
                              </Box>
                              {/* <Box>
                                <Typography component="h6" variant="h6">
                                  {value.firstDrawPrize}
                                </Typography>
                              </Box> */}
                              <Box sx={{ marginTop: "20px" }}>
                                {" "}
                                <Card
                                  sx={{
                                    maxWidth: "144px",
                                    width: "144px",
                                    border:
                                      "1.2px solid rgba(255, 255, 255, 0.2)",
                                  }}
                                  style={content}
                                >
                                  <div style={{ position: "relative" }}>
                                    <CardMedia
                                      sx={{
                                        height: "40.5px",
                                        backgroundColor: "#3C37FF",
                                        position: "relative",
                                        backgroundPosition: "initial",
                                      }}
                                      title="Next Draw"
                                      style={center}
                                    >
                                      Next Draw
                                    </CardMedia>
                                  </div>
                                  <CardContent
                                    sx={{
                                      padding: "7px",
                                      paddingBottom: "7px !important",
                                    }}
                                    style={center}
                                  >
                                    <Typography
                                      sx={{ marginBottom: "0px" }}
                                      gutterBottom
                                    >
                                      <Countdown
                                        date={
                                          new Date(`${value?.lotteryDrawDate}`)
                                        }
                                      />
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Box>
                            </Stack>
                          </CardContent>
                        </Box>

                        {value?.soldStatus ? (
                          <Chip
                            label="Sold Out"
                            sx={{
                              position: "absolute",
                              transform: "rotate(-45deg)",
                              top: "2px",
                              left: "-31px",
                              backgroundColor: "red",
                              color: "#FFFFFF",
                              borderRadius: "4px",
                              padding: "4px 17px",
                              fontSize: "12px",
                              fontWeight: "bold",
                              zIndex: "1000",
                            }}
                          />
                        ) : (
                          <></>
                        )}
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" marginTop="40px">
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                  sx={{
                    "& .MuiPaginationItem-root": {
                      color: "white",
                      fontSize: "18px",
                    },
                    "& .MuiPaginationItem-page.Mui-selected": {
                      backgroundColor: "primary",
                      color: "white",
                      fontSize: "25px",
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
