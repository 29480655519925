/* eslint-disable no-template-curly-in-string */
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  Grid,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";

import HomeScreenImg from "@/assets/earth.jpg";
import quickDrawsImg from "@/assets/quickDrawsImg.png";
import { useAuth } from "@/lib/auth";
import { useLotteries } from "../api/getReplayLottery";
import { CircularProgress } from "@mui/material";
import { theme } from "@/components/Elements/theme";
import comingsoon from "@/assets/comingSoon.png";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  paperClass: {
    padding: theme.spacing(2),
    textAlign: "center",
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
  },
}));

const dataValue = {
  heading: "Last Lottery Spin",
  subHeading:
    "Buy lottery tickets online for the top international lotteries. Safely and securely playfor the biggest jackpots in the entire world from the comfort of home.",
};

export default function ReplayLotteries() {
  const classes = useStyles();
  const { user } = useAuth();
  const [page, setPage] = useState(1);
  const limit = 10;

  const { data, isLoading } = useLotteries({ page, limit });
  console.log(data?.data, "data");

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  var content = {
    color: "#ffff",
    backgroundColor: "#050518",
    borderRadius: "12px",
  };
  var center = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  if (data?.data.length === 0) {
    return (
      <Box
        className="w-full h-48 flex justify-center items-center"
        sx={{
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          border: "1px solid",
          marginTop: "90px",
          minHeight: "calc(100vh - 330px)",
          display: "flex",
          alignItems: "center",
          "@media screen and (max-width:992px)": {
            marginTop: "66px",
          },
        }}
      >
        <img src={comingsoon} alt="comingsoon" style={{ width: "100%" }} />
      </Box>
    );
  }

  const gridItem = {};
  return (
    <Box
      sx={{
        background: `linear-gradient(89.41deg, #1B1C2D 7.81%, rgba(27, 28, 45, 0) 38.09%), linear-gradient(1.53deg, #1B1C2D 13.32%, rgba(27, 28, 45, 0) 50.41%), linear-gradient(46.49deg, #1B1C2D 14.7%, rgba(27, 28, 45, 0) 43.39%), url(${HomeScreenImg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: "100%",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        paddingBlock: "100px 70px",
      }}
    >
      <Box
        sx={{
          maxWidth: "95%",
          margin: "0 auto",
          [theme.breakpoints.down("md")]: {},
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "768px",
            margin: {
              xs: "10px auto 30px",
              sm: "30px auto 40px",
              md: "60px auto",
            },
            textAlign: "center",
            fontFamily: '"Ubuntu", sans-serif',
            fontSize: "25px",
            lineHeight: "1.5",
          }}
        >
          <Typography
            component="h4"
            sx={{
              textTransform: "capitalize",
              fontWeight: "500 !important",
              marginBottom: { xs: "18px", md: "25px" },
              fontSize: { xs: "20px", md: "30px" },
            }}
          >
            Last Played Lottery
          </Typography>
          {/* <Typography
            component={"p"}
            sx={{
              color: "rgba(255, 255, 255, 0.7)",
              fontSize: "16px",
              lineHeight: "1.5",
            }}
          >
            Buy lottery tickets online for the top international lotteries.
            Safely and securely playfor the biggest jackpots in the entire world
            from the comfort of home.
          </Typography> */}
        </Box>
        <Box
          sx={{
            p: { xs: "30px", md: "40px" },
            backgroundColor: "rgb(95 95 95 / 80%)",
            borderRadius: "10px",
            mt: "0px",
            maxWidth: "1700px",
            marginInline: "0px",
          }}
        >
          <Grid container spacing={2} display={"flex"} direction={"column"}>
            <Grid item xs={12} sm={10} md={8} lg={6}>
              <Grid
                className="small-device-replayLottery"
                container
                display={"flex"}
                direction={"row"}
                alignItems="stretch"
                justifyContent="space-around"
                spacing={3.5}
              >
                {isLoading ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100vh"
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  data?.data?.map((value, index) => (
                    <Grid
                      item
                      style={gridItem}
                      key={index}
                      xs={12}
                      sm={6}
                      md={6}
                      lg={4}
                      xl={4}
                    >
                      <Card
                        sx={{
                          display: "flex",
                          flexDirection: {
                            xs: "column",
                            sm: "column",
                            md: "row",
                          },
                          alignItems: "stretch",
                          mt: { xs: "10px" },
                          maxWidth: "780px",
                          cursor: "pointer",
                          position: "relative",
                          "&:hover": {
                            backgroundColor: "#fff !important",
                            color: "black !important",
                            boxShadow: 20,

                            "& div div .MuiDivider-root": {
                              borderBottomColor: "black !important",
                            },
                            "& div div div p": {
                              backgroundColor: "#fff !important",
                              color: "black !important",
                            },
                            "& div div .MuiCardContent-root": {
                              backgroundColor: "#fff !important",
                              color: "black !important",
                            },
                            "& div div div div.MuiPaper-root": {
                              border: "1.23px solid #00000038 !important",
                              background: "#fff",
                              boxShadow: "none",
                            },
                            "& div div div div.MuiPaper-root .MuiCardContent-root":
                              {
                                border: "1.23px solid #00000038 !important",
                              },
                          },
                        }}
                        style={content}
                        onClick={() => {
                          window.location.assign(
                            "/app/liveLotteries/" + value._id
                          );
                        }}
                      >
                        <CardMedia
                          component="img"
                          image={
                            value.image
                              ? `${process.env.API_URL_PREFIX}/api/v1/admin/uploads/${value.image}`
                              : quickDrawsImg
                          }
                          alt="Live from space album cover"
                          sx={{
                            height: {
                              xs: "100px !important",
                              md: "250px !important",
                            },
                            width: { xs: "100%", md: "45%" },
                            minWidth: { xs: "100%", md: "45%" },
                            maxHeight: { xs: "240px", md: "100%" },
                            minHeight: { xs: "240px", md: "auto" },
                            zIndex: "2",
                            backgroundPosition: "initial",
                            objectFit: "unset !important",
                            borderRadius: "10px",
                            flex: { md: "1 0 45%", xs: "1 0 100%" },
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: { xs: "100%", md: "65%" },
                            minHeight: {
                              xs: "200px !important",
                              md: "250px !important",
                            },
                            boxSizing: "border-box",
                            overflow: "hidden",
                          }}
                        >
                          <CardContent
                            style={center}
                            sx={{
                              height: {
                                xs: "calc(100% - 200px) !important",
                                md: "auto !important",
                              },
                              paddingTop: "30px",
                            }}
                          >
                            <Stack
                              flex="1 1 100%"
                              justifyContent={center}
                              alignItems={center}
                              sx={{
                                "&:hover": {
                                  backgroundColor: "#fff !important",
                                  color: "black !important",
                                },
                              }}
                            >
                              <Box sx={{ width: "100%" , textAlign:"center" }}>
                                <Button
                                  sx={{
                                    px: "19px",
                                    py: "7.73px",
                                    marginBottom: "19px !important",
                                    backgroundColor: "rgba(60, 55, 255, 0.15)",
                                    color: "#3C37FF",
                                    borderRadius: "19px",
                                    display: { xs: "inline-block", md: "block" },
                                  }}
                                >
                                  {value.lotteryName}
                                </Button>
                                <Divider
                                  id="cardLine"
                                  sx={{
                                    borderBottom:
                                      "0.727162px dashed rgba(255, 255, 255, 0.5)",
                                    mx: "-80px",
                                    "&:hover": {
                                      borderBottom:
                                        "0.727162px dashed black !important",
                                    },
                                  }}
                                />
                              </Box>
                              <Box sx={{ width: "100%" }}>
                                <Typography component="h6" variant="h6">
                                  {/* {value.firstDrawPrize} */}
                                </Typography>
                              </Box>
                              <Box sx={{ marginTop: "20px" }}>
                                {" "}
                                <Card
                                  sx={{
                                    maxWidth: "144px",
                                    width: "144px",
                                    border:
                                      "1.2px solid rgba(255, 255, 255, 0.2)",
                                  }}
                                  style={content}
                                >
                                  <div style={{ position: "relative" }}>
                                    <CardMedia
                                      sx={{
                                        height: "40.5px",
                                        backgroundColor: "#3C37FF",
                                        position: "relative",
                                      }}
                                      title="Next Draw"
                                      style={center}
                                    >
                                      Draw
                                    </CardMedia>
                                  </div>
                                  <CardContent
                                    sx={{
                                      padding: "7px",
                                      paddingBottom: "7px !important",
                                      textAlign: "center",
                                      borderRadius: "8px",
                                    }}
                                   
                                  >
                                    <Typography
                                      sx={{
                                        marginBottom: "0px",
                                        fontSize: "15px",
                                      }}
                                      
                                      align="center"
                                    >
                                      {(() => {
                                        const rawDate = value?.endDate; 

                                        
                                        const formattedDate = rawDate
                                          ? moment(
                                              rawDate,
                                              "YYYY-MM-DD HH:mm:ss"
                                            ).format("DD-MM-YYYY")
                                          : "Invalid Date";

                                       
                                        return `${formattedDate} `;
                                      })()}
                                    </Typography>
                                    <Typography
                                      sx={{
                                     
                                        fontSize: "15px",
                                      }}
                                     
                                      align="center"
                                    >
                                      
                                      {value?.endTime || "Invalid Time"}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Box>
                            </Stack>
                          </CardContent>
                        </Box>

                        {value?.numTktUserPurchase > 0 ? (
                          <>
                            {" "}
                            <Chip
                              label={value?.numTktUserPurchase + " Tickets"}
                              sx={{
                                position: "absolute",
                                transform: "rotate(45deg)",
                                top: "7px",
                                right: "-42px",
                                backgroundColor: "blue",
                                color: "#FFFFFF",
                                borderRadius: "4px",
                                padding: "8px 26px",
                                fontSize: "12px",
                                fontWeight: "bold",
                                zIndex: { md: 1, xs: "2" },
                              }}
                            />
                            <div
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                width: 0,
                                height: 0,
                                borderTop: "20px solid transparent",
                                borderLeft: "20px solid transparent",
                                zIndex: 0,
                              }}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </Card>
                    </Grid>
                  ))
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" marginTop="40px">
                <Pagination
                  count={Math.ceil(data?.totalRecords / limit)}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                  sx={{
                    "& .MuiPaginationItem-root": {
                      color: "white",
                      fontSize: "18px",
                    },
                    "& .MuiPaginationItem-page.Mui-selected": {
                      backgroundColor: "primary",
                      color: "white",
                      fontSize: "25px",
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
